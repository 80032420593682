@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .location-heading {
    background: radial-gradient(
      93.58% 191.61% at 52.93% 88.19%,
      #0085ff 0%,
      #07cc61 32.91%,
      #a3cb03 56%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .footerHeaderStretch {
    font-stretch: 100;
  }

  @keyframes leftFloating {
    0% {
      top: 0;
      left: 0;
    }
    50% {
      top: 30%;
      left: 0;
    }
    100% {
      top: 0;
      left: 0;
    }
  }

  @keyframes rightFloating {
    0% {
      bottom: 0%;
      right: 0;
    }
    50% {
      bottom: 30%;
      right: 0;
    }
    100% {
      bottom: 0%;
      right: 0;
    }
  }

  .leftAnm {
    animation: leftFloating 10s infinite;
  }

  .rightAnm {
    animation: rightFloating 10s infinite;
  }
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SFPRODISPLAY/SFPRODISPLAYREGULAR.OTF"), url("./assets/fonts/SFPRODISPLAY/SFPRODISPLAYMEDIUM.OTF");
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./assets/fonts/SFPROTEXT/SF-Pro-Text-Regular.otf"), url("./assets/fonts/SFPROTEXT/SF-Pro-Text-Medium.otf"), url("./assets/fonts/SFPROTEXT/SF-Pro-Text-Bold.otf"),;
}